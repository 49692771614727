import { Component, OnInit } from '@angular/core';
import {  NgbModalConfig, NgbModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-rasoi-book',
  templateUrl: './rasoi-book.component.html',
  styleUrls: ['./rasoi-book.component.scss'],
  providers: [HttpClient, NgbModalConfig, NgbModal]

})
export class RasoiBookComponent implements OnInit {
  mailRequest: any;
  baseUrl: string;
  selectedSeva: any;
  modalType: string = 'mail';
  slots :any[] = [];
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  date: { year: number; month: number };
  bookingDate: any;
  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private http: HttpClient
  ){    
    config.backdrop = 'static';
    config.keyboard = false;
    
    let min = new Date;
    min.setDate(min.getDate() + 1);
    let max = new Date;
    max.setDate(max.getDate() + 30);    
    this.minDate = { year: min.getFullYear(), month: min.getMonth() + 1, day: min.getDate()};
    this.maxDate = { year: max.getFullYear(), month: max.getMonth() + 1, day: max.getDate()};
    this.mailRequest = {
      name: '',
      phone: '',
      aadhar: '',
      email: '',
      bookingDate:this.minDate,
      slot:'',
      subject: 'Ram rasoi booking',
      message: '',
      address : ''
    };
    this.baseUrl = 'https://ayodhyamawamandir.org/mailer/mail.php';
    this.slots = [
      '11:30 AM - 12:00 PM',
      '12:00 PM - 12:30 PM',     
      '12:30 PM - 01:00 PM',
      '01:00 PM - 01:30 PM',
      '01:30 PM - 02:00 PM',
      '02:00 PM - 02:30 PM',
      '02:30 PM - 03:00 PM',
    ];
  }

 
  public submitForm(content){
    var that = this;
    this.mailRequest.message = document.getElementById("printpage1")?.innerHTML;
    $.ajax({
      url: 'mailer/mailToMandir.php',
      method: 'POST',
      dataType: 'json',
      data: {
        name: this.mailRequest.name,
        email: this.mailRequest.email,
        subject: this.mailRequest.subject,
        message: '<p>We have received booking with following details.</p>' + this.mailRequest.message,
        phone : this.mailRequest.phone
      }, success: function (response) {
        if (response.status == 'success'){  
          var loc = that;       
          $.ajax({
            url: 'mailer/mailToUser.php',
            method: 'POST',
            dataType: 'json',
            data: {
              name: that.mailRequest.name,
              email: that.mailRequest.email,
              subject: 'Booking Confirmation',
              message: '<p>Your booking is confirmed with following details.</p>' + that.mailRequest.message,
              phone : that.mailRequest.phone
            }, success: function (response) {
              if (response.status == 'success'){
                loc.modalService.open(content); 
              }else {
                alert('Unable to send email. Please try again.');
              }
            }
          });
        }else {
          alert('Unable to send email. Please try again.');
        }
      }
    });
  }

  generatePdf(){
    html2canvas(document.getElementById("printpage")!).then(canvas => {
      // Few necessary setting options
       
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var width = pdf.internal.pageSize.getWidth();
      var height = canvas.height * width / canvas.width;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height)
      pdf.save('output.pdf'); // Generated PDF
      });
  }

  ngOnInit() {
    this.bookingDate = new Date(this.mailRequest.bookingDate.year, this.mailRequest.bookingDate.month - 1, this.mailRequest.bookingDate.day);
  }
  changeDate(event: any) {
    this.bookingDate = new Date(this.mailRequest.bookingDate.year, this.mailRequest.bookingDate.month - 1, this.mailRequest.bookingDate.day);
  }

}
