import { Component, OnInit, ViewChild, ElementRef,ViewEncapsulation } from '@angular/core';
import {NgbCarouselConfig, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {AppConfig,Gallery} from '../../assets/config/configuration';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
	encapsulation: ViewEncapsulation.None,
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit {

  constructor(config: NgbCarouselConfig, private modalService: NgbModal) { 
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }
  pageVariable : any;
  images :any;
  aboutTemple : string = '';
  showAlert : boolean = true;
  closeResult : string = '';
  services: any;

  @ViewChild('content', { static: true }) private content: ElementRef<any>;

  ngOnInit(): void {
    this.aboutTemple = AppConfig.ABOUTTEMPLE;

    this.pageVariable = {
      'heading' : 'Welcome to Shri Ram Janki Temple',
      'keyMemebers' : 'Key Memebers',
      'keyHighlights' : 'Key Highlights',
      'upcomingEvents': 'Upcoming Festivals',
      'timimgs' : 'Timings',
      'gallery' : 'Gallery',
      'information' : '',
      'about-us' : '',
      'organization' : '',

    }
    this.images = Gallery.HOMEPAGEIMAGES;
    this.services = AppConfig.SERVICES;

    this.open();
  }
  open() {
    this.modalService.open(this.content, 
      {ariaLabelledBy: 'modal-basic-title', 
        size: 'lg',
        backdrop : 'static',
        scrollable : true,
        windowClass: 'class-modal',
        keyboard : false}
      ).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
