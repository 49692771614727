<!-- Rasoi Booking -->
<section class="page-section " id="services">
   <div class="container">
     <div class="row">
       <div class="col-xl-12 text-center">
         <h2 class="mt-0">Book Ram Rasoi</h2>
         <div class="page-form">
            <div class="row form-container">
               <div class="calendar form-group"><label>
                  Date</label>
                  <div>
                     <ngb-datepicker #dp [(ngModel)]="mailRequest.bookingDate" (navigate)="date = $event.next"
                        [minDate]="minDate" [maxDate]="maxDate" (ngModelChange)="changeDate($event)" class="ngCalendar"></ngb-datepicker>
                  </div>
               </div>
               <div class="form-group-container">
                 <div class="form-group">
                   <label for="name">
                     Name</label>
                   <input type="text" class="form-control" id="name" [(ngModel)]="mailRequest.name" placeholder="Enter name"
                     required="required" />
                 </div>
                 <div class="form-group">
                   <label for="name">
                     Phone</label>
                   <input type="number" class="form-control" id="phone" [(ngModel)]="mailRequest.phone" placeholder="Phone Number"
                     required="required" />
                 </div>
                 <div class="form-group">
                  <label for="adhar">
                    Adhar</label>
                  <input type="number" class="form-control" id="adhar" [(ngModel)]="mailRequest.aadhar" placeholder="Aadhar Number"
                    required="required" />
                </div>
                <div class="form-group">
                  <label for="slot">
                     Slot</label>
                  <select class="form-control" id="slot" [(ngModel)]="mailRequest.slot" required>
                     <option *ngFor="let slot of slots" [value]="slot">{{slot}}</option>
                     </select>
                </div>
                 <div class="form-group">
                   <label for="email">
                     Email Address</label>
                   <div class="input-group">
                     <span class="input-group-addon"><span class="glyphicon glyphicon-envelope"></span>
                     </span>
                     <input type="email" [(ngModel)]="mailRequest.email" class="form-control" id="email" placeholder="Enter email"
                       required="required"/>
                   </div>
                 </div>
                 <div class="form-group" style="display: none;">
                   <label for="subject">
                     Ritual</label>
                     <input type="text" [(ngModel)]="mailRequest.subject" class="form-control" id="subject" placeholder="Enter ritual"
                       required="required"/>
                 </div>
               </div>
               <div class="col-md-12" style="display: none;">
                 <div class="form-group">
                     <label for="name">
                         Message</label>
                     <textarea [(ngModel)]="mailRequest.message" id="message" class="form-control" rows="2" cols="25" required="required"
                         placeholder="Message"></textarea>
                 </div>
               </div>
               <div class="col-md-12">
                 <button  [disabled]="!mailRequest.name || !mailRequest.phone || !mailRequest.email || !mailRequest.slot"  
                 (click)="submitForm(content)"  class="btn btn-primary pull-right" id="btnContactUs">
                   Book</button>
               </div>
            </div>
         </div>
       </div>
     </div>
   </div>
</section>

<ng-template #content let-modal>
   <div class="modal-header">
      <button type="button" class="btn btn-info" (click)="generatePdf()">Download</button>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>

   <div class="modal-body" id="printpage">
      <div style="text-align: center;">
         <img src="./assets/image/amawa_logo.png" style="width: 128px;"> 
      </div>
     <table>
      <tbody>
         <tr>
            <td>Name</td>
            <td>{{mailRequest.name}}</td>
         </tr>
         <tr>
            <td>Aadhar</td>
            <td>{{mailRequest.aadhar}}</td>
         </tr>
         <tr>
            <td>Phone</td>
            <td>{{mailRequest.phone}}</td>
         </tr>
         <tr>
            <td>Slot</td>
            <td>{{bookingDate | date:'mediumDate'}} , {{mailRequest.slot}}</td>
         </tr>
         <tr>
            <td>Email</td>
            <td>{{mailRequest.email}}</td>
         </tr>
      </tbody>
     </table>
     <p style="margin-top: 120px;text-align: right;">*Please authorise this from mandir.</p>
 
   </div>
 </ng-template>

<div style="display: none;">
  <div id="printpage1">
    <table >
      <tbody>
         <tr>
            <td>Name</td>
            <td>{{mailRequest.name}}</td>
         </tr>
         <tr>
            <td>Aadhar</td>
            <td>{{mailRequest.aadhar}}</td>
         </tr>
         <tr>
            <td>Phone</td>
            <td>{{mailRequest.phone}}</td>
         </tr>
         <tr>
            <td>Slot</td>
            <td>{{bookingDate | date:'mediumDate'}} , {{mailRequest.slot}}</td>
         </tr>
         <tr>
            <td>Email</td>
            <td>{{mailRequest.email}}</td>
         </tr>
      </tbody>
    </table>
    <br>
    <br>
    <p>Thanks,</p>
    <p>Amawa Ram Mandir, Ayodhya</p>
  </div>
</div>
