<!-- <section class="banner bg_cover" id="hero" style="background-image: url('../../assets/image/gallery/01.jpg');">
  <div class="container">
    <h1>Welcome to Amawa Ram Mandir Ayodhya.</h1>
  </div>
</section> -->

<!-- Masthead -->
<section class="masthead"  id="home">
  <!-- <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center text-center">
        <div class="col-lg-10 align-self-end">
          <h1 class="text-uppercase text-white font-weight-bold">Welcome to Amawa Ram Mandir</h1>
        </div>
    </div>
  </div> -->
  <ngb-carousel *ngIf="images" [showNavigationArrows]="true" [showNavigationIndicators]="true">
    <ng-template ngbSlide *ngFor="let image of images">
      <div class="picsum-img-wrapper">
        <img [src]="image" alt="Random slide">
      </div>
      <!-- <div class="carousel-caption">
        <h3>Welcome to Amawa Ram Mandir</h3>
      </div> -->
    </ng-template>
  </ngb-carousel>
</section>
<!-- About Section -->
<section class="page-section" id="about">
  <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
            <h2 class="mt-0">About Us</h2>
            <hr class="divider light my-4">
            <div class="slides">
              <div class="col-xs-12">
                <p>{{aboutTemple}}</p>
              </div>
            </div>
        </div>
      </div>
  </div>
</section> 
<!-- Services Section -->
<section class="page-section " id="services">
  <div class="container">
      <div class="row">
      <div class="col-xl-12 text-center">
        <h2 class="mt-0">Services</h2>
        <hr class="divider light my-4">
      </div> 

      <div class="col-12 data-row">
          <div class="card">
            <div class="card-header">
              <h4>Seva</h4>
            </div>      
            <div class="card-content">          
              <ul>
                  <li *ngFor="let service of services">
                    <a routerLink="/services">
                      <i class="fa fa-bell" aria-hidden="true"></i>
                      {{service}}</a>
                  </li>

              </ul>
            </div>
          </div>
          <!-- <div class="card">
            <div class="card-header">
              <h4>Upcoming Events</h4>
            </div>      
            <div class="card-content">          
              <ul>
                  <li class="calender-row">
                      <div class="date-box">
                          <span>May</span><br>
                          <p>10</p>
                          <span>2022</span>
                      </div>
                      <div>
                          <p>Ramayan Pratiyogita</p>
                      </div>
                  </li>
              </ul>
            </div>
          </div> -->
          <div class="card">
            <div class="card-header">
              <h4>Program</h4>
            </div>      
            <div class="card-content">          
              <ul>
                  <li><p><i class="fa fa-bell" aria-hidden="true"></i>Morning Archana</p></li>
                  <li><p><i class="fa fa-bell" aria-hidden="true"></i>Morning Bell</p></li>                    
                  <li><p><i class="fa fa-bell" aria-hidden="true"></i>Kirtan</p></li>
                  <li><p><a routerLink="/ram-rasoi"><i class="fa fa-bell" aria-hidden="true"></i>Ram Rasoi</a></p></li>
                  <li><p><i class="fa fa-bell" aria-hidden="true"></i>Evening Archana</p></li>
                  <li><p><i class="fa fa-bell" aria-hidden="true"></i>Ramayan Path</p></li>
              </ul>
            </div>
          </div>
    
        </div>
      </div>
  </div>
  
</section>

<ng-template #content let-modal>
  <div class="modal-header">
		<!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">Close</button>
	</div>
  <div class="modal-body">
    <p>
      अयोध्या का प्रसिद्ध अमावा राम मंदिर उस स्थान पर बना हुआ है, जिस स्थान पर त्रेताकाल में रघुकुल का देवालय था जहाँ भगवान् श्रीराम, विष्णु, षिव तथा अन्य देवताओं की आराधना करते थे।	इस अमावा राम मंदिर परिसर में 18 मंदिर हैं जिनमें सभी देवताओं की भव्य अचल तथा चल मूर्तियाँ स्थापित हैं। बालरूप में राम और वाल्मीकि आश्रम में सीता माता की मूर्ति देखने पर मन वहाँ से हटने का नाम नहीं लेता।
      <br/><br/>
	इस अमावा राम मंदिर परिसर में तीर्थ यात्रियों के लिए महावीर मंदिर, पटना के सौजन्य से राम रसोई में तीनों समय निःशुल्क भोजन की व्यवस्था है; सुबह अल्पाहार, दोपहर एवं रात्रि में ऐसा स्वादिष्ट भरपूर भोजन प्रेम से मिलता है कि भक्त इसका स्वाद भूलते नहीं। आप अवश्य राम रसोई में प्रसाद ग्रहण करें।
      <br/><br/>
मंदिर में सनातन धर्म के सभी देव विराजमान हैं; उनकी विधिवत् पूजा कराने का कर्मकाण्ड चलता है जिसमें रामार्चा तथा रूद्राभिषेक सबसे अधिक महत्वपूर्ण है। इसके अलावे महामृत्युंजय जप तथा सत्यनारायण-कथा का भी आयोजन होता है। आप अवधपुरी में कोई भी अनुष्ठान कराके असीम पुण्य के भागी बनेंगे। 
<br/><br/>
	अमावा राम मंदिर तिरूपति के कुशल कारीगरों के द्वारा गाय के घी में बना हुआ बेजोड़ स्वाद का रघुपति लड्डू भी सस्ते दाम पर सुलभ कराता है। कम मात्रा में रघुपति पेड़ा प्रसाद भी उपलब्ध रहता है। अयोध्या यात्रा को इस प्रसाद से यादगार बनायें। 
<br/><br/>
	अमावा राम मंदिर में ज्योतिष कार्यालय भी है। इसमें योग्य एवं अनुभवी ज्योतिषियों द्वारा जन्मकुण्डली बनाने से लेकर भविष्यवाणी बताने तक का विधान है। ज्योतिष कार्यालय में कम दाम की वास्तुशास्त्र-सम्मत मालाओं से लेकर बहुमूल्य राशि-रत्नों को भी यन्त्र द्वारा प्रमाणित कर सुलभ कराया जाता है।
<br/><br/>
	मंदिर परिसर में ’रामावत’ संस्था है तथा दीक्षा का अनुपम अनुष्ठान भी होता है जिसमें भक्त और भगवान् के बीच कोई नहीं रहता। ’’जात-पात पूछै नहीं कोई, हरि को भजै, सो हरि को होई’’ के सिद्धान्त पर किसी के साथ भेदभाव किए बिना अग्रसर होने की भावना विकसित की जाती है और भक्ति के पथ पर चलकर आध्यात्मिक उपलब्धि प्राप्त की जाती है। 
<br/><br/>
			(आयें और रामावत संगत से जुड़ें)
    </p>
  </div>
</ng-template>